import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component/lib/util';
import template from './RecapitulatifEchantillons.Template.vue';
import { GrilleMixin } from '@/shared/mixins/Grille.mixin';
import Confirm from '@/components/Confirm.vue';
import { Echantillon } from '@/models/Echantillon.model';
import { EchantillonCriteria } from '../EchantillonCriteria.model';
import ApiHelper from '@/services/ApiHelper';
import RecapitulatifEchantillonsValidator from './RecapitulatifEchantillonsValidator';
import CeeAutocomplete from '@/components/CeeAutocomplete.vue';
import ApiAutocompleteHelper from '@/services/ApiAutocompleteHelper';

@Component({
    ...template,
    name: 'RecapitulatifEchantillons',
    components: {
        Confirm,
        CeeAutocomplete
    }
})
export default class RecapitulatifEchantillons extends mixins(RecapitulatifEchantillonsValidator, GrilleMixin)  {
    public $refs!: Vue['$refs'] & {
        confirm: {
            open: ((title: string | null, message: string | null, options: { color?: string, width?: number, zIndex?: number }) => Promise<boolean>),
        },
        form: HTMLFormElement,
    };

    public listeEchantillon: Echantillon[] = [];
    public loading = false; 
    public loadingEchantillons = false; 
    public criteresAvances: EchantillonCriteria = new EchantillonCriteria();

    public created() {
        this.getData();
    }

    public getData() {
        this.getAllEchantillon();
    }

    public getAllEchantillon(): void {
        this.loadingEchantillons = true;
        this.$http.get(`/echantillon/obtenirTous`)
            .then((result) => {
                const { data: { data: responseData } } = result as ({ data: { data: Echantillon[], isError: boolean, messages: any[] } });
                if (!!responseData && responseData.length > 0) {
                    this.listeEchantillon = responseData;
                }
            }).finally(() => this.loadingEchantillons = false);
    }

    /**
    * Generation recapitulatif.
    * @memberof Reporting
    */
    public genererRecapitulatif(): void {
        if (this.$refs.form.validate()) {
            this.loading = true;
            this.$http.post(`/echantillon/exportEchantillonExcel`, this.criteresAvances)
                .then((reponse) => {
                    ApiHelper.createAndDownloadBlobFile(reponse.data);
                }).catch().finally(() => this.loading = false);
        }
    }

    // Liste des noms des échantillons. 
    public async nomsEchantillonsPromise(recherche: string): Promise<Echantillon[]> {
        const data = await ApiAutocompleteHelper.getAutocompletePromise<Echantillon>(recherche, `echantillon/obtenirListeEchantillonsForAutocomplete`);
        return data;
    }
}
